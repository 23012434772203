import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

// import { Container, Row, Col } from "react-bootstrap"

import outdoorLighting from "../../images/landscape-lighting.jpg"

const SecondPage = () => (
  <Layout>
    <Seo title="Home Automation Installation" />
    <h1>Home Automation Installation</h1>
    <p>More and more homeowners are shifting to automated Smart Homes, which enable them to monitor and control their energy usage from a smart phone or tablet. Master electrician Thomas Tripolt of All-Electric can do all of your home automation installation. He will help you convert your home to an energy efficient automated home, with voice activation from your smart phone or tablet. With home automation installed, you get to dictate how an electrical device should react, when it should react, and why it should react. You set up a schedule and the rest is automated, based on your personal preferences. At any time, you can use your iPhone, Android device or another remote control to change your home automation settings as desired. You not only save money, you also get the convenience of an overall smarter home.</p>
    <p><strong>When you are at home,</strong> you can use your home automation system to dim the lights when you desire. You can adjust your home’s temperature from your bed or control the volume of your whole-house audio system from any room. Or imagine having a wall/ceiling heater in your bathroom coming on automatically on chilly mornings 5 minutes before your alarm clock goes off so that it is warm when you enter.</p>
    <p><strong>When you are away,</strong> home automation can alert you to any events that you might want to know about immediately, like water leaks or an unexpected access to your home.</p>
    <h2>INSTEON Whole-House Wireless Automation</h2>
    <p>Thomas Tripolt has years of experience working with INSTEON whole-house wireless automation systems. In fact, All Electric is a certified INSTEON installer. INSTEON offers a full suite of wireless products, including: home security cameras, door sensors, motion sensors, smart outlets, sprinkler controls, smart dimmers, smart light switches, thermostats and more.</p>
    <h2>Lutron Wireless Automation</h2>
    <p>Lutron is the manufacturer that invented the dimmer in the sixties. The company has a very wide variety of automation products for the home as well as for commercial applications. <span className="text-danger">Lutron’s CASETA</span> wireless home automation, for example, is a solid system that makes your lighting and shade control easy and affordable. Thomas Tripolt can address your Lutron installation needs.</p>
    <h2>Home Automation for Safety and Security</h2>
    <p>Always on guard and at the ready, home automation provides additional security for you and your home. From a security camera’s watchful eye to a water sensor that will alert you of a costly leak, an automated home will keep you informed so that you can react at a moment’s notice. We’re all used to opening the garage door from the car, but you’ll be surprised how much safer you’ll feel coming home to an already lit home, or being able to turn on lights from your remote upon your arrival.</p>
    <p>With a few basic products you can have your whole house light up when there is motion detected outside. Imagine your house sending you an email or text while you are away if there is motion where there shouldn’t be any. Or, you can have your security system call you if there is an alarm, which might include your typical security alarm or water detected in the laundry room or basement.</p>
    <p>Would you like to have visitors call you from your gate rather than come to your door? Thomas can install an intercom system that provides audio or audio and video from your gate. You can have the audio integrated into your home, your Smartphone or both. Thomas can also install a security solution that opens your gate with a keypad.</p>
    <p className="text-center">
      <img src={outdoorLighting} alt="outdoor lights" className="img-fluid" />
    </p>
    <h2>Landscape Lighting</h2>
    <p>Do you have a few trees in your yard that would look beautiful if they were lit at night? Or, would you like security lighting installed? Thomas Tripolt can install landscape lighting for both beauty and safety that can be controlled with your home automaton system.</p>
  </Layout>
)

export default SecondPage
